<template>
  <div class="login_layout">
    <div class="logo"><img src="@/assets/img/login_logo.png" alt="" srcset=""></div>
    <div class="form_container">
      <router-view />
    </div>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped> .login_layout {
   width: 100%;
   height: 100vh;
   background-image: url(~@/assets/img/login_back.png);
   background-position: left;
   background-size: 100%;
   position: relative;

   .logo {
     position: absolute;
     top: 32px;
     left: 32px;
     max-width: 185px;

     img {
       max-width: 100%;
     }
   }

   .form_container {
     width: 432px;
     height: 660px;
     position: absolute;
     right: 6%;
     top: 0;
     bottom: 0;
     margin: auto;
     background-color: #fff;
     border-radius: 16px;
     box-shadow: 0 5px 15px #ccc;
     overflow: hidden;
   }
 }
</style>
